<ng-container *ngIf="{
  portals: portals$ | async,
  device: device$ | async
} as obs">

  <div
    class="topbar__items"
    *ngIf="obs.portals?.length"
  >
    <ng-container
      [ngTemplateOutlet]="obs.device === Device.Desktop ? desktopTpl : tabletTpl"
      [ngTemplateOutletContext]="{ portals: obs.portals }"
    ></ng-container>
  </div>
</ng-container>

<ng-template
  #desktopTpl
  let-portals="portals"
>
  <ng-container
    *ngFor="let portal of portals"
    [cdkPortalHost]="portal"
  ></ng-container>
</ng-template>

<ng-template
  #tabletTpl
  let-portals="portals"
>
  <button
    mat-button
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div
      mat-menu-item
      *ngFor="let portal of portals"
    >
      <ng-container [cdkPortalHost]="portal"></ng-container>
    </div>
  </mat-menu>
</ng-template>
