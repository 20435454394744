<ng-container *ngIf="{
  routes: routes$ | async,
  device: device$ | async
} as obs">

  <div class="navigator">
    <ng-container *ngTemplateOutlet="
    obs.device === Device.Desktop ? desktopTpl : tabletTpl;
    context: { routes: obs.routes }
    "></ng-container>
  </div>

</ng-container>

<ng-template #desktopTpl
             let-routes="routes">
  <ng-container *ngFor="let route of routes, let routeIndex = index">

    @if(route.active) {
    <span class="not-active">{{ route.title }}</span>
    }
    @else {
    <kt-generic-button appearance="link"
                       (click)="navigateTo(route.path)">{{ route.title }}</kt-generic-button>
    }

    <ng-container *ngIf="(routeIndex + 1) !== routes.length">
      <mat-icon [style.display]="'flex'"
                [style.align-items]="'center'"
                [style.justify-content]="'center'">chevron_right</mat-icon>
    </ng-container>

  </ng-container>
</ng-template>

<ng-template #tabletTpl
             let-routes="routes">
  <ng-container *ngIf="routes.length <= 2">
    <span class="not-active">{{ routes[routes.length - 1].title }}</span>
  </ng-container>

  <ng-container *ngIf="routes.length > 2">

    @if(routes[routes.length - 2].active) {
    <span class="not-active"><mat-icon>chevron_left</mat-icon></span>
    }
    @else {
    <kt-generic-button appearance="icon"
                       (click)="navigateTo(routes[routes.length - 2].path)">chevron_left</kt-generic-button>
    }


    <span class="not-active">{{ routes[routes.length - 1].title }}</span>

  </ng-container>

</ng-template>