import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, ProviderToken, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';

import { Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { GenericButtonComponent } from '@shared/ui/common';
import { StringUtil } from '@core/utils';
import { Device, DeviceService } from '@core/services';
import { AppRouteError } from '@core/entities/routing';
import { NavigationTitleResolver, RouteInfo } from './navigator.model';

@Component({
  standalone: true,
  selector: 'kt-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    GenericButtonComponent,
    MatIconModule,
    NgFor,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class NavigatorComponent {
  static DYNAMIC_PATH_SYMBOL = ':';

  private injector = inject(Injector);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private deviceService = inject(DeviceService);

  readonly Device = Device;
  get device$() {
    return this.deviceService.device$;
  }

  readonly routeMap$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => this.route.routeConfig ? this.buildRouteMap(this.route.routeConfig) : new Map<string, RouteInfo>()),
  );

  readonly dynamicTitlesMap = new Map<string, string>();

  readonly routes$ = this.routeMap$.pipe(
    map(routeMap => {
      const urlSegments = this.router.url.split('/').filter(Boolean);

      const paths: RouteInfo[] = Array.from(routeMap)
        .map(([path, routeInfo]) => {
          const routeSegments = path.split('/').filter(Boolean);
          const active = this.isRouteActive(routeSegments, urlSegments);
          const segments = active ? urlSegments : routeSegments;

          const lastRouteSegmentIdx = routeSegments.length - 1;
          const dynamic = Boolean(routeSegments.at(lastRouteSegmentIdx)?.startsWith(NavigatorComponent.DYNAMIC_PATH_SYMBOL));
          return {
            title: routeInfo.title,
            ...routeInfo.titleResolver && { titleResolver: routeInfo.titleResolver },
            segments,
            active,
            dynamic,
            ...dynamic && { pathId: urlSegments.at(lastRouteSegmentIdx) },
          };
        })
        .reduce((
          acc: Omit<RouteInfo, 'path'>[],
          route: Omit<RouteInfo, 'path'>
        ) => {

          if (route.segments.length > urlSegments.length) {
            return acc;
          }

          let replaceIndex = -1;
          let matchCount = 0;
          route.segments.forEach((segment, segmentIndex) => {
            if (segment === urlSegments[segmentIndex]) {
              matchCount += 1;
            } else if (segment.startsWith(NavigatorComponent.DYNAMIC_PATH_SYMBOL)) {
              matchCount += 1;
              replaceIndex = segmentIndex;
            }
          });

          if (route.segments.length === matchCount && replaceIndex !== -1) {
            route.segments[replaceIndex] = urlSegments[replaceIndex];
          }

          return [...acc, route];

        }, [] as RouteInfo[])
        .filter(route =>
          route.segments.every(
            (navSegment, navSegmentIndex) =>
              (navSegment.startsWith(NavigatorComponent.DYNAMIC_PATH_SYMBOL) && urlSegments[navSegmentIndex] !== undefined) ||
              navSegment === urlSegments[navSegmentIndex]
          )
        )
        .map((route, routeIndex) => {
          return {
            ...route,
            path: `/${route.segments.join('/')}`,
            title: StringUtil.capitalize(route.title || route.segments[routeIndex]),
          };
        })

      return paths;
    }),
    switchMap(routes => forkJoin(routes.map(r => of(r).pipe(
      switchMap(route => {
        if (route.dynamic && route.pathId && route.titleResolver) {
          return this.getDynamicSegmentTitle(route.pathId, route.titleResolver).pipe(
            map(title => ({ ...route, title }))
          );
        }

        return of(route);
      })
    )))),
  );

  navigateTo(path: string): void {
    this.router.navigate([path]);
  }

  private isRouteActive(routeSegments: string[], urlSegments: string[]): boolean {
    return routeSegments.every((routeSegment, routeSegmentIndex) => {
      if (routeSegments.length !== urlSegments.length) {
        return false;
      }

      if (routeSegment.startsWith(NavigatorComponent.DYNAMIC_PATH_SYMBOL)) {
        return true;
      }

      return routeSegment === urlSegments[routeSegmentIndex];
    });
  }

  private buildRouteMap(
    config: Route,
    initialPath?: string,
    routeMap = new Map<string, RouteInfo>(),
    parentConfig?: Route,
  ): Map<string, RouteInfo> {

    if (config.redirectTo) {
      if (config.data && config.data['menuConfig']) {
        throw new AppRouteError(
          `Invalid route configuration '${config.path || parentConfig?.path || '/'}': redirectTo and menuConfig cannot be used together`
        );
      }

      return routeMap;
    }

    const rootPath = `${initialPath ? initialPath : ''}${config.path ? '/' + config.path : ''}`;
    if (rootPath) {
      routeMap.set(rootPath, {
        ...(config.data && config.data['menuConfig']),
        path: rootPath,
      });
    }

    if (Array.isArray(config.children)) {
      for (const child of config.children) {
        this.buildRouteMap(child, rootPath, routeMap, config);
      }
    }

    return routeMap;
  }

  private getDynamicSegmentTitle(pathId: string, titleResolver: ProviderToken<NavigationTitleResolver>): Observable<string> {
    const title = this.dynamicTitlesMap.get(pathId);

    if (title) {
      return of(title);
    }

    let service;
    try {
      service = this.injector.get(titleResolver);
    } catch (error) {
      return of(pathId);
    }

    if (!service?.getTitle) {
      return of(pathId);
    }

    return service.getTitle(pathId).pipe(
      take(1),
      tap(title => this.dynamicTitlesMap.set(pathId, title || pathId)),
      catchError(err => {
        console.error(err);
        return of(pathId);
      })
    )
  }
}
